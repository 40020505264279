import React, { Component } from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import UnderlineButton from "../Core/UnderlineButton"

const Form = styled.form`
  margin-bottom: 100px;
  
  @media(min-width: ${breakpoints.md}){
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

const Submitted = styled.div`
  ${fonts.galvjiRegular};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey};
`;

const ErrorMessage = styled.div`

`;

const HoneyPot = styled.div`
  display: none;
`;

const Label = styled.label`
  ${fonts.galvjiBold};
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.024px;
  text-transform: uppercase;
  color: ${colors.lightGold};
  display: block;
`;

const Input = styled.input`
  line-height: 24px;
  letter-spacing: 0.032px;
  ${fonts.galvjiRegular};
  font-size: 16px;
  border: none;
  border-bottom: 1px solid ${colors.backgroundGrey};
  border-radius: 0;
  display: block;
  width: 100%;
  outline: none;
  margin-bottom: 24px;
  padding-bottom: 10px;
  color: ${colors.grey};
  padding-left: 0;
  :focus,
  :hover {
    border-color: ${colors.lightGold};
    transition: 0.2s ease-in;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-moz-placeholder { /* Firefox 18- */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  placeholder { 
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 65px ${colors.white} inset !important;
    color: ${colors.grey};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    ${fonts.galvjiRegular};
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.grey};
    color: ${colors.grey};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    ${fonts.galvjiRegular};
  }
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 14px;
    padding-bottom: 6px;
  }
`;

const Textarea = styled.textarea`
  border: none;
  border-bottom: 1px solid ${colors.backgroundGrey};
  border-radius: 0;
  display: block;
  width: 100%;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.032px;
  color: ${colors.grey};
  ${fonts.galvjiRegular};
  resize: none;
  outline: none;
  height: 36px;
  max-height: 132px;
  padding-left: 0;
  :focus,
  :hover {
    border-color: ${colors.lightGold};
    transition: 0.2s ease-in;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-moz-placeholder { /* Firefox 18- */
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  placeholder { 
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 65px ${colors.white} inset !important;
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.grey};
    ${fonts.galvjiRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    color: ${colors.grey};
  }

`;

const Submit = styled.div`
  display: block;
  margin-top: 52px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 77px;
  }
`;

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
  formSubmitted: false,
  status: null,
  maxHeight: false,
}

class ContactForm extends Component {
  state = initialState;

  handleChange = (event) => {
    let target = event.target

    let stateObject = {}
    stateObject[target.name] = target.value

    this.setState(stateObject)
  }

  handleSubmit = (event) => {
    event.preventDefault()

    let contactForm = document.getElementById("contact-form")
    let formData = new FormData(contactForm)

    this.setState({status: 'pending'})

    fetch(contactForm.getAttribute("action"), {
      method: "POST",
      body: formData,
    }).then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          this.setState(initialState)

          this.setState({
            formSubmitted: true,
            status: 'success',
          })
        } else {
          this.setState({
            formSubmitted: true,
            status: 'failed',
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  componentDidUpdate = () => {
    if (typeof window !== "undefined") {
      let messageArea = document.getElementById("message") || undefined
      if (messageArea) {
        messageArea.addEventListener("keyup", () => {
          messageArea.style.height = this.autoHeight(messageArea.value) + "px"
        })
      }
    }
  }

  autoHeight(value) {
    let numberOfLineBreaks = (value.match(/\n/g) || []).length
    let newHeight
    let atMax = this.state.maxHeight
    newHeight = 36 + numberOfLineBreaks * 24
    if (!atMax && numberOfLineBreaks > 4) {
      atMax = true
      this.setState({ maxHeight: atMax })
    }
    if (atMax && numberOfLineBreaks < 4) {
      atMax = false
      this.setState({ maxHeight: atMax })
    }
    return newHeight
  }

  render() {
    const { handleSubmit, handleChange } = this;
    const { name, email, phone, message, status } = this.state;

    return (
      <Form method="POST" name="contact" data-netlify="true" id="contact-form" data-netlify-honeypot="bag"
            onSubmit={handleSubmit}>
        {status === "success" ? (
            <div>
              <Submitted>Merci! Votre message a bien été envoyé.</Submitted>
            </div>
          )
          :
          <React.Fragment>
            <div>
              <>
                <Label htmlFor={name}>Nom</Label>
                <Input onChange={handleChange}
                       id="name"
                       type="text"
                       name="name"
                       value={name}
                       placeholder="Votre nom"
                       aria-label="Name"/>
              </>
              <>
                <Label htmlFor={email}>Email</Label>
                <Input onChange={handleChange}
                       id="email"
                       type="email"
                       name="email"
                       value={email}
                       placeholder="Adresse email"
                       aria-label="Email"/>
              </>
              <>
                <Label htmlFor={name}>Téléphone</Label>
                <Input onChange={handleChange}
                       id="phone"
                       type="text"
                       name="phone"
                       value={phone}
                       placeholder="Votre numéro de téléphone"
                       aria-label="Phone"/>
              </>
              <>
                <Label htmlFor={name}>Message</Label>
                <Textarea onChange={handleChange}
                          id="message"
                          name="message"
                          value={message}
                          placeholder="Votre message..."
                          aria-label="Message"/>
              </>
              <HoneyPot>
                <input name="bag" aria-label="contact"/>
              </HoneyPot>
              <input type="hidden" name="form-name" value="contact" aria-label="contact"/>
              <Submit>
                <UnderlineButton gold button type={"submit"}>
                  Envoyer
                </UnderlineButton>
              </Submit>
            </div>
            {status === "failed" && <ErrorMessage>Une erreur est survenue. Merci de réessayer.</ErrorMessage>}
          </React.Fragment>
        }
      </Form>
    )
  }
}

export default ContactForm
