import React, {useState} from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Core/Seo";
import ContentContainer from "../components/Core/ContentContainer";
import {Container, Row, Col} from "styled-bootstrap-grid";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";
import fonts from "../styles/fonts";
import colors from "../styles/colors";
import PhoneIcon from "../resources/img/ui/gold-phone.svg";
import MailIcon from "../resources/img/ui/gold-email.svg";
import PinIcon from "../resources/img/ui/gold-pin.svg";
import ExternalLink from "../components/Core/ExternalLink";
import Img from "gatsby-image";
import ContactForm from "../components/Contact/ContactForm"
import BlockContainer from "../components/Core/BlockContainer"

const ContactContainer = styled.div`
  margin-top: 142px;

  @media(min-width: ${breakpoints.md}){
    margin-top: 174px;
    margin-bottom: 150px;
  }
`;

const Title = styled.h1`
  ${fonts.ralewayRegular};
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: ${colors.darkGrey};
  margin-bottom: 20px;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 48px;
    line-height: 62px;
    letter-spacing: 14.4px;
    margin-bottom: 11px;
  }
`;

const Text = styled.span`
  ${fonts.galvjiRegular};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.028px;
  color: ${colors.grey};
  display: block;
  
  @media(min-width: ${breakpoints.md}){
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032px;
    max-width: 360px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 16px;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 40px;
    margin-bottom: 73px;
  }
`;

const ImageBlock = styled.div`
  position: relative;
  margin-bottom: 80px;
  
  @media(min-width: ${breakpoints.md}){
    max-width: 420px;
    margin-bottom: 0;
  }
  @media(min-width: ${breakpoints.xl}){
    max-width: 520px;
    margin-bottom: 0;
  }
  @media(min-width: ${breakpoints.max}){
    max-width: 620px;
    margin-bottom: 0;
  }
`;

const ImgStyled = styled(Img)`
  min-height: 160px;
  
  @media(min-width: ${breakpoints.md}){
    height: 216px;
  }
  @media(min-width: ${breakpoints.xl}){
    height: 280px;
  }
  @media(min-width: ${breakpoints.max}){
    height: 380px;
  }
`;

const Overlay = styled.div`
  display: none;
  @media(min-width: ${breakpoints.md}){
    display: block;
    height: 100%;
    width: 100%;
    background: ${props => props.hover ? `${colors.lightGold}` : `${colors.backgroundGrey}`};
    transition: background-color 0.3s;
    position: absolute;
    top: -28px;
    right: -28px;
  }
`;

const StyledLink = styled(ExternalLink)`
  margin-bottom: 14px;
  width: max-content;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  span {
    transition: all 0.2s ease-in;
    position: relative;
    :after {
      content: "";
      width: 0%;
      height: 1px;
      background: ${colors.lightGold};
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.2s ease-in;
    }
  }
  span:hover {
    :after {
      content: "";
      width: 100%;
      height: 1px;
      background: ${colors.lightGold};
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  
  &:first-of-type {
    img {
      margin-right: 11.2px;
      height: 17px;
      width: 12.77px;
    }
  }
  
  &:nth-of-type(2) {
    img {
      margin-right: 10.4px;
      height: 11px;
      width: 14.58px;
      margin-top: 5px;
    }
  }
  
  &:nth-of-type(3) {
    img {
      margin-right: 11.7px;
      height: 16px;
      width: 12.26px;
      margin-top: 4px;
    }
  }
  
  @media(min-width: ${breakpoints.md}){
    &:first-of-type {
      img {
        margin-top: 2px;
      }
    }
  
    &:nth-of-type(2) {
      img {
        margin-top: 7px;
      }
    }
  
    &:nth-of-type(3) {
      img {
        margin-top: 4px;
      }
    }
  }
`;

const ContactInfo = styled.div`
  padding-right: 54px;
`;

const ContactPage = (props) => {
  const [hover, setHover] = useState(false);
  const path = props.location.pathname;
  const page = props.data.page;

  return (
    <Layout darkStyle={true}>
      <SEO fullTitle={true}
           path={path}
           title={page.metaTitle}
           description={page.metaDescription}/>
      <BlockContainer>
        <ContactContainer>
          <Container fluid>
            <ContentContainer>
              <Row>
                <Col md={12} lg={10} lgOffset={1} xxl={12} xxlOffset={0}>
                  <Title>{page.title}</Title>
                </Col>
                <Col md={6} lg={5} lgOffset={1} xxl={6} xxlOffset={0}>
                  <ContactInfo>
                    <Text>{page.description}</Text>
                    <Links>
                      <StyledLink href={"tel:0618984589"}>
                        <img src={PhoneIcon} alt={"Phone"}/>
                        <Text>06 18 98 45 89</Text>
                      </StyledLink>
                      <StyledLink href={"mailto:stephen@stephensaada.com"}>
                        <img src={MailIcon} alt={"Email"}/>
                        <Text>stephen@stephensaada.com</Text>
                      </StyledLink>
                      <StyledLink href={"https://goo.gl/maps/sw6NeVkHUmzVwtqL8"}>
                        <img src={PinIcon} alt={"Location"}/>
                        <Text>
                          184 Boulevard Pereire,<br/>
                          75017 Paris, France
                        </Text>
                      </StyledLink>
                    </Links>
                    <ImageBlock onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                      <Overlay hover={hover}/>
                      <ExternalLink href={"https://goo.gl/maps/sw6NeVkHUmzVwtqL8"}>
                        <ImgStyled fluid={page.blocks[0].image.fluid} alt={"Location"}/>
                      </ExternalLink>
                    </ImageBlock>
                  </ContactInfo>
                </Col>
                <Col md={5} lg={4} mdOffset={1} xxl={5}>
                  <ContactForm/>
                </Col>
              </Row>
            </ContentContainer>
          </Container>
        </ContactContainer>
      </BlockContainer>
    </Layout>
  )
}

export default ContactPage;

export const pageQuery = graphql`
  query ContactQuery {
    page: contentfulPage(slug: {eq: "contact"}) {
      title
      description
      metaTitle
      metaDescription
        blocks {
          __typename
          ... on Node {
            ... on ContentfulHero {
              image {
                fluid(maxWidth: 856 quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            }
        }
    }
  }
`
